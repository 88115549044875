import PropTypes from 'prop-types'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import React from 'react'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import ReactHtmlParser from 'react-html-parser'

const EventListItem = ({ title, url, startDate, endDate, municipalities, content }) => (
	<li className="py-10 mb-6 md:mb-10 md:border-none bg-white rounded-md shadow-sm md:bg-transparent md:shadow-none">
		<div className='px-6 md:px-0'>
			<span className="text-sm block font-bold text-green-accent mb-1 md:text-base">
				{format(new Date(startDate.replace(/\s+/g, 'T')), 'd MMMM', { locale: sv })} •{' '}
				{municipalities.map(municipality => municipality.name).join(' - ')}
			</span>
			<h3
				className="text-lg md:text-2xl max-w-xl font-bold leading-tight mb-2"
				dangerouslySetInnerHTML={{ __html: title }}
			></h3>
			<p className="font-bold mb-2">
				Tid:{' '}
			{format(
					new Date(startDate.replace(/\s+/g, 'T')),
					'HH:mm',
					{ locale: sv }
				)}{' '} - {format(
					new Date(endDate.replace(/\s+/g, 'T')),
					'HH:mm',
					{ locale: sv }
				)}{' '}
			</p>
			<p className="text-base text-justify mb-6 w-full text-gray-500">
				{ReactHtmlParser(content)}
			</p>
			<OutboundLink
				href={url}
				target="_blank"
				className="underline text-lg font-bold md:font-normal"
			>
				Information och anmälan
			</OutboundLink>
		</div>
	</li>
)

EventListItem.propTypes = {
	title: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
}

export default EventListItem
