import React, { useState, useEffect} from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { HiArrowLeft } from 'react-icons/hi'
import { parse } from 'date-fns'
import Img from 'gatsby-image'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import EventListItem from '../components/EventListItem'

const EventsPage = () => {
	const data = useStaticQuery(graphql`
		query AllEventsQuery {
			allWordpressWpEvent {
				edges {
					node {
						id
						title
						slug
						content
						municipality {
							name
						}
						acf {
							end_date
							start_date
							link
						}
					}
				}
			}
			wordpressAcfOptions {
				options {
					footer_logotypes {
						link
						image {
							id
							localFile {
								childImageSharp {
									fluid(quality: 100) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
						}
					}
					visibility
				}
			}
		}
	`)

	const images = data.wordpressAcfOptions?.options?.footer_logotypes
	const visibility = data.wordpressAcfOptions?.options?.visibility

	const [widgetOpen, setWidgetOpen] = useState(false);

	useEffect(() => {
		const iframe = document.getElementById('business-halland-widget')

		if (!iframe) {
			console.error('Business Halland is not initialized.')
			return
		}

		if (widgetOpen){
			iframe.contentWindow.postMessage({ type: 'WIDGET_OPEN' }, '*')
		} else {
			iframe.contentWindow.postMessage({ type: 'WIDGET_CLOSE' }, '*')
		}
		
	}, [widgetOpen])

	const upcomingEvents = data.allWordpressWpEvent.edges
		.filter(event => {
			let eventDate = parse(
				event.node.acf.end_date,
				'yyyy-M-d k:m:s',
				new Date()
			)
			return eventDate > new Date()
		})
		.sort((a, b) => {
			const aDate = parse(
				a.node.acf.start_date,
				'yyyy-MM-dd kk:m:s',
				new Date()
			)
			const bDate = parse(
				b.node.acf.start_date,
				'yyyy-MM-dd kk:m:s',
				new Date()
			)
			return aDate - bDate
		})


	return (
		<Layout>
			<SEO title="Evenemang" metaTitle='Business Halland' description='Business Halland gör det enklare för dig som företagare att hitta och ta del av de erbjudan och evenemang i Halland.' />
			<div className="bg-green-bg w-full pt-10">
				<div className="max-w-screen-2xl px-6 lg:px-10 xl:px-20 mx-auto">
					<div className="w-auto block">
						<button
							className="text-green-accent text-previous font-bold underline mb-12 flex items-center focus:outline-none"
							onClick={() => navigate(-1)}
						>
							<HiArrowLeft className="mr-2" />
							Gå tillbaka
						</button>
					</div>
					{/* {firstEvent ? (
						<div className="mx-auto max-w-90p">
							<div
								className={
									'bg-green-accent py-10 px-4 text-white md:bg-green-bg md:px-0 ' +
									'md:py-8 mb-6 relative md:shadow-sm rounded-img rounded-xl'
								}
							>
								{firstEvent.node.acf.bild.localFile
									.childImageSharp.fluid && (
									<StyledImg
										style={{ position: 'absolute' }}
										className="h-full w-full bottom-0 top-0 left-0 z-0 hidden md:inline-block after-darken md:rounded-xl"
										objectFit="cover"
										objectPosition="50% 50%"
										fluid={
											firstEvent.node.acf.bild.localFile
												.childImageSharp.fluid
										}
									/>
								)}
								<div
									className={
										'mx-auto md:rounded-xl md:py-8 md:px-6 lg:px-10 ' +
										'xl:px-20 z-30 relative'
									}
								>
									<div className="w-full px-3 mx-auto md:p-0">
										<span className="text-sm block font-bold mb-4 uppercase md:mb-1">
											{format(new Date(firstEvent.node.acf.start_date.replace(/\s+/g, 'T')), 'dd MMMM', { locale: sv })}
											{' '}•{' '}
											{firstEvent.node.municipality
												.map(m => m.name)
												.join(' - ')}
										</span>
										<h1
											className={
												'font-bold leading-tight text-xl md:text-3xl md:pb-2 ' +
												'lg:text-4xl border-b border-white pb-8 md:border-none'
											}
											dangerouslySetInnerHTML={{ __html: firstEvent.node.title }}
										></h1>
										<p className="font-bold">
											Tid:{' '}
										{format(
												new Date(
													firstEvent.node.acf.start_date.replace(/\s+/g, 'T')
												),
												'HH:mm',
												{ locale: sv }
											)}{' '} - {format(
												new Date(
													firstEvent.node.acf.end_date.replace(/\s+/g, 'T')
												),
												'HH:mm',
												{ locale: sv }
											)}{' '}
										</p>
										<p className="mb-6 pt-4 w-full lg:w-8/12 text-justify">
											{ReactHtmlParser(
												firstEvent.node.content
											)}
										</p>
										<OutboundLink
											href={firstEvent.node.acf.link}
											className="underline text-lg font-bold"
										>
											Gå till anmälan
										</OutboundLink>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div
							className={
								'py-10 md:px-0 ' +
								'md:py-8 relative'
							}
						>
							<div
								className={
									'mx-auto md:rounded-xl md:py-8 ' +
									'z-30 relative'
								}
							>
								<div className="w-full px-3 mx-auto md:p-0">
									<h3 className="text-lg md:text-3xl max-w-xl font-bold leading-tight mb-2">
										Oj! - Här var det tomt.
									</h3>
									<p className="text-base mb-6 w-3/5">
										Det finns för närvarande inga planerade evenemang. Vi uppdaterar evenemangskalendern frekvent så kom tillbaka senare! Vill du se mer om våra erbjudande så klicka på knappen!
									</p>
									<button
										className="cursor-pointer bg-green-accent px-6 py-3 outline-none focus:outline-none text-white text-center rounded-lg mt-5 mr-5 w-56"
										onClick={() => setWidgetOpen(!widgetOpen)}
									>
										Utforska erbjudanden
									</button>
								</div>
							</div>
						</div>
					)} */}

					<div className="mx-auto py-10 md:py-0 max-w-90p">
						<ul className="list-reset md:px-6 lg:px-10 xl:px-20">
							{upcomingEvents.map(
								({
									node: {
										id,
										title,
										content,
										municipality = [],
										acf: { link, start_date, end_date },
									} = ({} = {}),
								}) => (
									<EventListItem
										key={id}
										title={title}
										content={content}
										url={link}
										startDate={start_date}
										endDate={end_date}
										municipalities={municipality}
									/>
								)
							)}
						</ul>
						<div className="h-20 bg-green-bg"></div>
					</div>
				</div>
			</div>
			{visibility && (
				<div className={
					"mx-auto block px-3 py-10 " +
					"max-w-screen-2xl md:px-6 lg:px-10 xl:px-20"}>
						<div className="w-full font-bold text-2xl md:text-2xl">
							Nyfiken på fler evenemang?
						</div>
						<div className="grid grid-cols-3 md:grid-cols-6 gap-4 w-full">
							{ images && images.map((el) => (
								<div key={el.id}>
									<a href={el.link} target="_blank" className='flex items-center w-full h-full'>
										<Img
											className="w-32 h-24"
											imgStyle={{
												objectFit: "contain",
												objectPosition: "50% 50%",
											}}
											fluid={el.image?.localFile?.childImageSharp?.fluid}
										/>
									</a>
								</div>
							))}
						</div>
				</div>
			)}
		</Layout>
	)
}

const StyledImg = styled(Img)`
	&:before {
		content: '';
		${tw`absolute block w-full h-full z-10`}
		background: linear-gradient(
			to top,
			rgba(0, 0, 0, 0.5),
			rgba(0, 0, 0, 0)
		);
	}
`

export default EventsPage
